:root {
  --White: #ffffff;
  --OffWhite: #f5f5f5;
  --Black: #000000;
  --Overlay: #0000003b;
  --Gray: #eae9e9;
  --HeaderGray: #f8f9fa;
  --LightGray: #737373;
  --DarkGray: #636363;
  --Green: #8bc34a;
  --LightGreen: #92bb6a;
  --Red: #dc3545;
  --Yellow: #ffcc00;
  --Blue: #427cd8;
  --LightBlue: #eef7fe;
}
select {
  -webkit-appearance:none;
}
select option{
  -webkit-appearance:none;
  color: var(--DarkGray) !important;
  background-color: var(--Gray) !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}
::-webkit-scrollbar-thumb {
  background: var(--Gray) !important;
  border-radius: 5px;
}
[class*="formWrap"]::-webkit-scrollbar-thumb,
[class*="makeStyles-overflow"]::-webkit-scrollbar-thumb {
  background: var(--Gray) !important;
  border-radius: 5px;
}
a {
  text-decoration: none;
  color: var(--Green);
}
.MuiRadio-colorSecondary.Mui-checked,
.MuiPickersMonth-monthSelected,
.MuiTypography-colorPrimary {
  color: var(--Green) !important;
}
.MuiFilledInput-underline:after,
.MuiFilledInput-underline:before {
  border: none !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: var(--Gray) !important;
}
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: var(--Gray) !important;
}
.MuiInputLabel-outlined,
.MuiInputLabel-filled {
  color: var(--LightGray) !;
}
.MuiPickersDay-daySelected,
.MuiPickersToolbar-toolbar,
.MuiPickersToolbar-root,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb,
.MuiPickersClock-pin {
  background-color: var(--Green) !important;
}
.MuiClockPointer-thumb,
.MuiPickersClockPointer-thumb {
  border: 14px solid var(--Green) !important;
}
.MuiClockNumber-root {
  color: white !important;
}

.MuiDialogActions-root .MuiButton-textPrimary {
  color: var(--DarkGray);
}
.MuiFormControlLabel-label {
  color: var(--DarkGray);
  font-size: 13px !important;
}
.MuiDialogActions-root .MuiButton-textPrimary:nth-child(2) {
  color: var(--Green);
}
#transition-modal-title {
  border-bottom: solid thin var(--Gray);
}
div[class^="makeStyles-searchWrapper"]
  .MuiButtonBase-root:not(.MuiButton-contained):focus {
  transform: translateY(-5px);
  transition: all 0.5s ease;
}
fieldset {
  width: 100%;
  border: solid thin var(--Gray);
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgb(255 255 255 / 20%),
    0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(97 97 97 / 12%) !important;
}
.layout {
  max-width: 100%;
  overflow-x: hidden;
  margin-bottom: 50px;
}
.react-grid-item:hover > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 10px;
  height: 10px;
  border-right: 4px solid var(--DarkGray);
  border-bottom: 4px solid var(--DarkGray);
}
.move {
  position: absolute !important;
  top: 17px;
  left: 16px;
  width: 33px !important;
  height: 27px !important;
  z-index: 9;
  opacity: 0;
  cursor: grab;
}
.remove {
  opacity: 0;
}
.react-grid-item:hover .move,
.react-grid-item:hover .remove {
  opacity: 1 !important;
}
.react-grid-item > .react-resizable-handle {
  cursor: se-resize;
}
.react-draggable-dragging,
.resizing {
  border: none !important;
  background-color: transparent !important;
}
.react-grid-layout {
  position: relative;
}
.mui-rte-editor-container {
  height: 100%;
}
.tox-tinymce {
  height: 100% !important;
  min-height: 200px;
}
.required{
  background-color: red;
}
.MuiButtonBase-root.MuiPickersDay-root {
  background-color: var(--DarkGray) !important;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: var(--Green) !important;
}
.MuiPaper-root.MuiPickersPopper-paper {
  color: white !important;
}