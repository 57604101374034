body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.time-input {
  width: 100%;
  position: relative;
}

.time-input > input {
  width: 100%;
  height: 42px;
  border: 1px solid white;
  background: transparent;
  border-radius: 5px;
  color: white;
  padding: 10.5px;
  font-family: inherit;
    font-size: 1rem;
}

.time-input > label {
  position: absolute;
  top: -10px;
  left: 0px;
  background: transparent;
  color: white;
  font-size: 1rem;
  transform: scale(0.75);
  background: #636363;
  padding: 0 5px;
}